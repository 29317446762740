import { rxStompConfig } from '../config/rx-stomp.config';

export const apiAccessPoint = 'https://api-release-gie-proxy.k8s.object23.it/';

export const environment = {
  env: 'release',
  apiBaseUrl: apiAccessPoint + 'v1',
  apiAccessPointSiteMobile: 'https://release-gie-mobile.k8s.object23.it/',
  production: false,
  stompConfig: rxStompConfig
};
