import { InjectableRxStompConfig } from '@stomp/ng2-stompjs';

export const rxStompConfig: InjectableRxStompConfig = {
    // brokerURL: 'ws://192.168.1.46:61614',
    brokerURL: 'wss://messaging-activemq-release-gie-proxy.k8s.object23.it',
    connectHeaders: {
        login: 'user',
        passcode: 'password',
    },
    heartbeatIncoming: 0,
    heartbeatOutgoing: 20000,
    reconnectDelay: 200,

    debug: (msg: string): void => {
        console.log(new Date(), msg);
    },
};
